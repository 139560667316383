import React, { Component } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
export default class Plan extends Component {
  componentDidMount(prevProps) {
    document.getElementById("BookingHead").scrollIntoView();
    document.getElementById("BookingForm__anchor").scrollIntoView();
  }
  render() {
    const { id } = this.props.match.params;
    if (id === 'booked') {
      return (
        <div className="Booking">
          <div id="BookingHead" className="BookingHead">
            <Link className="BookingClose" to={"/catalog"}></Link>
          </div>
          <section id="BookingForm" className="BookingForm BookingContainer">
            <div id="BookingForm__anchor"></div>
            <h2>Квартира забронирована</h2>
            <div className="BookingFormBody BookingFormBody--grey">
              <p>Пожалуйста выберите другую квартиру для покупки</p>
            </div>
          </section>
        </div>
      );
    }
    return (
      <div className="Booking">
        <div id="BookingHead" className="BookingHead">
          <Link className="BookingClose" to={"/catalog"}></Link>
        </div>
        <section id="BookingForm" className="BookingForm BookingContainer">
          <div id="BookingForm__anchor"></div>
          <h2>Процесс электронной сделки</h2>
          <div className="BookingFormBody">
            <article className="BookingProcessBox">
              <div className="BookingProcessNumber">
                <span>1</span>
              </div>
              <div>
                <h3>Сбор документов</h3>
                <p>
                  Подготовьте документы для оформления договора, их набор
                  зависит от формы собственности (скан паспорта, свидетельства о
                  рождении и т.п.)
                </p>
              </div>
            </article>
            <article className="BookingProcessBox">
              <div className="BookingProcessNumber">
                <span>2</span>
              </div>
              <div>
                <h3>Выпуск ЭЦП</h3>
                <p>
                  Подпишите заявление и сертификат на выпуск ЭЦП. Время и место
                  согласовывается с менеджером.
                </p>
              </div>
            </article>
            <article className="BookingProcessBox">
              <div className="BookingProcessNumber">
                <span>3</span>
              </div>
              <div>
                <h3>Внесение денег</h3>
                <p>
                  При 100% оплате внесите сумму по договору на аккредитив.
                  Аккредитив можно открыть онлайн через менеджера отдела продаж
                  без посещения банка через систему безопасных расчетов (СБР).
                  При покупке в ипотеку получите одобрение с помощью менеджера
                  отдела продаж или воспользуйтесь специальным партнёрским
                  предложением — ипотека по двум документам.
                </p>
              </div>
            </article>
            <article className="BookingProcessBox">
              <div className="BookingProcessNumber">
                <span>4</span>
              </div>
              <div>
                <h3>Подписание документов с помощью ЭЦП</h3>
                <p>
                  Поставьте электронную подпись под пакетом документов. Вы
                  получите уникальный код по SMS — его ввод и будет подписанием.
                  Фактическое списание денежных средств будет произведено после
                  регистрации ДДУ в Росреестре. 5 Регистрация сделки в
                  Росреестре Процесс регистрации начнётся сразу после подписания
                  документов через уникальный код SMS и продлится до 10 рабочих
                  дней. 6 Оплата После завершения регистрации сумма по договору
                  будет автоматически переведена с аккредитива. Пакет
                  зарегистрированных документов придет, на указанный вами адрес
                  электронной почты. Распечатанный и подписанный вариант можно
                  забрать в отделе продаж в любое удобное время.
                </p>
              </div>
            </article>
          </div>
        </section>
        <div className="BookingFooter ">
          <div className="BookingContainer">
            <Link
              className="BookingBtn"
              to={"/profile/" + this.props.match.params.id}
            >
              Далее
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
