import conf from "../conf.json";

export const getToken = () => {
  return fetch(conf.api_url + "authentication", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      type: "api-app",
      credentials: {
        pb_api_key: conf.api_key
      }
    })
  })
    .then(response => response.json())
    .then(responseJson => responseJson.access_token)
    .catch(console.error);
}

export const getFlatsList = () => {
  return fetch("https://ucitynew.dvfx.ru/flats/getFlats.php")
    .then(results => results.json())
    .catch(console.error);
}

/*export const getFlatsList = async () => {
  let page = 0;
  const token = await getToken();
  let flats = [];
  while(1) {
    const flatsP = await getFlatsListPage(token, page);
    flats = flats.concat(flatsP);
    page += 1;
    if (flatsP.length < 100) {
      break;
    }
  }
  return flats;
}

export const getFlatsListPage = (token, page = 0) => {
  let url = `${conf.api_url}property?full=true&offset=${page * 100}&status[]=AVAILABLE&status[]=BOOKED&projectId=${conf.project_id}&access_token=${token}`;

  return fetch(url)
    .then(results => results.json())
    .then(data => data.data)
    .catch(console.error);
};*/

export const getFlatImgs = () => {
  return fetch(conf.api_url + "authentication", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      type: "api-app",
      credentials: {
        pb_api_key: conf.api_key
      }
    })
  })
    .then(response => response.json())
    .then(responseJson => responseJson.access_token)
    .then(token =>
      fetch(
        conf.api_url +
          "plan?access_token=" +
          token +
          "&projectId=" +
          conf.project_id +
          "&status[]=AVAILABLE&status[]=BOOKED"
      )
    )
    .then(results => results.json())
    .then(data => {
      let plan_imgs = [];
      data.data.forEach(plan => {
        plan.properties.forEach(item => {
          plan_imgs[item] = plan.image.source;
        });
      });
      return plan_imgs;
    })
    .catch(console.error);
};

export const getFloorPlans = houseId => {
  return fetch(conf.api_url + "authentication", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      type: "api-app",
      credentials: {
        pb_api_key: conf.api_key
      }
    })
  })
    .then(response => response.json())
    .then(responseJson => responseJson.access_token)
    .then(token =>
      fetch(
        conf.api_url + "floor?access_token=" + token + "&houseId=" + houseId
      )
    )
    .then(results => results.json())
    .then(data => {
      let floor_plans = [];
      data.forEach(plan => {
        floor_plans[plan.number] = plan.images.source;
      });
      return floor_plans;
    })
    .catch(console.error);
};
