import React, { Component } from "react";
import { connect } from "react-redux";

class Finishing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flat: this.getFlatById(this.props.match.params.id),
    };
  }
  getFlatById(id) {
    return this.props.flats.find((flat) => flat.id == id);
  }
  componentDidMount(prevProps) {
    document.getElementById("BookingForm").scrollIntoView();
    document.getElementById("BookingForm__anchor").scrollIntoView();
  }
  render() {
    return (
      <div>
        <section
          id="BookingForm"
          className="BookingForm BookingContainer"
          style={{
            textAlign: "center",
            background: "none",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <div id="BookingForm__anchor"></div>
          <h2 style={{ fontSize: "42px", lineHeight: "1.23" }}>
            Заявка на бронирование
          </h2>
          <div
            className="BookingFormBody"
            style={{ display: "block", fontSize: "24px", lineHeight: "1.5" }}
          >
            Вы бронируете квартиру: {this.state.flat.area.area_total} кв.м.,
            {this.state.flat.houseName}, {this.state.flat.sectionName}
          </div>
          <div className="Booking__info">
            <p>
              Вы забронировали квартиру на 3 рабочих дня. В указанный срок вам
              необходимо предоставить документы в наш офис продаж.
            </p>
          </div>
          <div style={{ background:"none" }} className="BookingContainer">
            Нажимая на кнопку, вы соглашаетесь на обработку{" "}
            <a
              claclassNamess="privacypolicy"
              href="https://u-city.ru/privacypolicy/"
              target="_blank"
            >
              персональных данных
            </a>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}
Finishing = connect(mapStateToProps)(Finishing);
export default Finishing;
