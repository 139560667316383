import React from "react";

const RCheckbox = ({ title, id, text, value, onChangeValue }) => {
  return (
    <div className="r-checkbox r-form-group">
      <div className="r-checkbox__title">{title}</div>
      <div className="r-checkbox__body">
        <input
          value={value}
          id={id}
          type="checkbox"
          onChange={onChangeValue}
          checked={value}
        />
        <label htmlFor={id}></label>
        <div className="r-checkbox__text">{text}</div>
      </div>
    </div>
  );
};

export default RCheckbox;
