import React, { Component } from "react";

import RSelect from "../ui/RSelect";
import RInput from "../ui/RInput";
import RCheckbox from "../ui/RCheckbox";
import RDate from "../ui/RDate";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, Redirect } from "react-router-dom";
import moment from "moment"

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: false,
      fio: "",
      pasport: "",
      email: "",
      phone: "",
      owner: "",
      date: "",
      pay: null,
      citizenship: true,
      flat: this.getFlatById(this.props.match.params.id),

      dateErr: false,
      fioErr: false,
      pasportErr: false,
      emailErr: false,
      phoneErr: false,
      ownerErr: false,
      payErr: false,

      dateNotice: false,
      pasportNotice: false,
      fioNotice: false,
      requiredNotice: false,
      emailNotice: false,
      phoneNotice: false,

      isRedirect: false,
    };
    console.log(props);
    this.sendData = this.sendData.bind(this);
  }
  items = [
    { id: 1, value: "100% оплата" },
    { id: 2, value: "Рассрочка" },
    { id: 3, value: "Ипотека (нужно одобрение)" },
    { id: 4, value: "Ипотека (есть одобрение)" },
  ];
  getFlatById(id) {
    return this.props.flats.find((flat) => flat.id == id);
  }
  validate() {
    return (

      this.fioValidate() &&
      this.dateValidate() &&
      this.pasportValidate() &&
      this.emailValidate() &&
      this.phoneValidate() &&
      this.ownerValidate() &&
      this.payValidate()
    );
  }
  async sendData() {
    if (this.validate()) {
      this.setState({
        request: true,
      });
      await fetch("https://ucity.dvfx.ru/email.php", {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({
          fio: this.state.fio,
          pasport: this.state.pasport,
          email: this.state.email,
          phone: this.state.phone,
          owner: this.state.owner,
          date: this.state.date.toDate(),
          pay: this.state.pay,
          citizenship: this.state.citizenship,
          flat: this.state.flat,
        }),
      });
      this.setState({
        isRedirect: true,
        request: false,
      });
    }
  }
  dateValidate =() => {
    let valid = this.state.date !== "";
    this.setState({ dateErr: !valid, requiredNotice: !valid });
    console.log('dateValidate:', this.state.date)

    let mask = moment.isMoment(this.state.date)

    this.setState({
      dateErr: !valid || !mask,
      dateNotice: !mask,
    });
    console.log('dateValidate:',valid , mask)
    return valid && mask;
  }

  fioValidate = () => {
    let valid = this.state.fio.length > 0;
    this.setState({ fioErr: !valid, requiredNotice: !valid });
    let mask = /^[A-Za-zА-Яа-яЁё\s]+$/.test(this.state.fio);
    this.setState({
      fioErr: !valid || !mask,
      fioNotice: !mask,
    });
    return valid && mask;
  };
  pasportValidate = () => {
    let valid = this.state.pasport.length > 0;
    this.setState({ pasportErr: !valid, requiredNotice: !valid });
    let mask = /[0-9]{4}[\s][0-9]{6}/.test(this.state.pasport);
    this.setState({
      pasportErr: !valid || !mask,
      pasportNotice: !mask,
    });
    return valid && mask;
  };
  emailValidate = () => {
    let valid = this.state.email.length > 0;
    this.setState({ requiredNotice: !valid });
    let mask = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/.test(this.state.email);
    this.setState({
      emailErr: !valid || !mask,
      emailNotice: !mask,
    });
    return valid && mask;
  };
  phoneValidate = () => {
    let valid = this.state.phone.length > 0;
    this.setState({ phoneErr: !valid, requiredNotice: !valid });
    let mask = /^\+\d{1}\s\(\d{3}\)\s\d{3}[-]\d{2}[-]\d{2}$/.test(
      this.state.phone
    );
    console.log(this.state.phone, mask);

    this.setState({
      phoneErr: !valid || !mask,
      phoneNotice: !mask,
    });

    return valid && mask;
  };
  ownerValidate = () => {
    let valid = this.state.owner.length > 0;
    this.setState({ ownerErr: !valid, requiredNotice: !valid });
    return valid;
  };
  payValidate = () => {
    let valid = this.state.pay !== null;
    this.setState({ payErr: !valid, requiredNotice: !valid });
    return valid;
  };

  updateFio = (e) => this.setState({ fio: e.target.value });
  updatePasport = (e) => this.setState({ pasport: e.target.value });
  updateEmail = (e) => this.setState({ email: e.target.value });
  updatePhone = (e) => this.setState({ phone: e.target.value });
  updateOwner = (e) => this.setState({ owner: e.target.value });
  updatePay = (value) => this.setState({ pay: value });
  updateСitizenship = (e) =>
    this.setState({ citizenship: !this.state.citizenship });
  updateDate = (value) => this.setState({ date: value });

  componentDidMount(prevProps) {
    document.getElementById("BookingHead").scrollIntoView();
    document.getElementById("BookingForm__anchor").scrollIntoView();
  }

  render() {
    return (
      <div className="Booking">
        <div id="BookingHead" className="BookingHead">
          <Link className="BookingClose" to={"/catalog"}></Link>
        </div>
        <section id="BookingForm" className="BookingForm BookingContainer">
          <div id="BookingForm__anchor"></div>

          <h2 className="BookingForm-header">Заявка на бронирование</h2>
          <p className="BookingForm-description">
            Вы бронируете объект: {this.state.flat.area.area_total} кв.м.,
            {this.state.flat.houseName}, {this.state.flat.sectionName}
          </p>
          <RInput
            value={this.state.fio}
            onChangeValue={this.updateFio}
            title="ФИО"
            placeholder="Фамилия имя и отчество"
            pattern="^[A-Za-zА-Яа-яЁё\s]+$"
            className="r-input-box"
            err={this.state.fioErr}
          />
          <RDate
            value={this.state.date}
            onChangeValue={this.updateDate}
            title="Дата рождения"
            placeholder="Дата рождения"
            err={this.state.dateErr}
          />
          <RCheckbox
            value={this.state.citizenship}
            onChangeValue={this.updateСitizenship}
            title="Гражданство"
            id="citizenship"
            text="Я гражданин РФ"
          />
          <RInput
            value={this.state.pasport}
            onChangeValue={this.updatePasport}
            title="Серия и номер паспорта"
            placeholder="Серия и номер паспорта"
            mask="9999 999999"
            className="r-input-box"
            err={this.state.pasportErr}
          />
          <RInput
            value={this.state.email}
            onChangeValue={this.updateEmail}
            title="Ваш e-mail"
            placeholder="E-mail"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
            className="r-input-box"
            err={this.state.emailErr}
          />
          <RInput
            value={this.state.phone}
            onChangeValue={this.updatePhone}
            title="Ваш телефон"
            placeholder="+7 (999) 999-99-99"
            mask="+7 (999) 999-99-99"
            className="r-input-box"
            err={this.state.phoneErr}
          />
          <RInput
            value={this.state.owner}
            onChangeValue={this.updateOwner}
            title="Кто будет собственником"
            placeholder="ФИО собственников"
            pattern="^[A-Za-zА-Яа-яЁё\s]+$"
            className="r-input-box"
            err={this.state.ownerErr}
          />
          <RSelect
            value={this.state.pay}
            onChangeValue={this.updatePay}
            title="Планируемый способ оплаты"
            placeholder="Выберите вариант из списка"
            items={this.items}
            err={this.state.payErr}
          />
          <div
            className={
              this.state.fioNotice ||
              this.state.requiredNotice ||
              this.state.emailNotice ||
              this.state.pasportNotice ||
              this.state.phoneNotice ||
              this.state.dateNotice
                ? "Booking__err active"
                : "Booking__err"
            }
          >
            <p
              className={
                this.state.pasportNotice ? "err-box active" : "err-box"
              }
            >
              Укажите, пожалуйста, серию и номер паспорта
              <br />
            </p>
            <p className={this.state.fioNotice ? "err-box active" : "err-box"}>
              Укажите, пожалуйста, имя
              <br />
            </p>
            <p className={this.state.dateNotice ? "err-box active" : "err-box"}>
              Укажите, пожалуйста, дату
              <br />
            </p>
            <p
              className={
                this.state.requiredNotice ? "err-box active" : "err-box"
              }
            >
              Обязательное поле
              <br />
            </p>

            <p
              className={this.state.emailNotice ? "err-box active" : "err-box"}
            >
              Некорректный e-mail
              <br />
            </p>

            <p
              className={this.state.phoneNotice ? "err-box active" : "err-box"}
            >
              Некорректный номер
              <br />
            </p>
          </div>
          <div className="Booking__btn-box">
            {this.state.request ? (
              <div className="Booking__btn-box__preload"><div /></div>
            ) : (
              <button className="r-button r-form-group" onClick={this.sendData}>
                Отправить
              </button>
            )}
            <br />
            Нажимая на кнопку, вы соглашаетесь на обработку{" "}
            <a
              className="privacypolicy"
              href="https://u-city.ru/privacypolicy/"
              target="_blank"
            >
              персональных данных
            </a>
          </div>
          {this.state.isRedirect ? (
            <Redirect to={"/finishing/" + this.props.match.params.id} />
          ) : (
            ""
          )}
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}
Plan = connect(mapStateToProps)(Plan);
export default Plan;
