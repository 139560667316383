import React, { Component } from "react";
import { credit_calc } from "../calc.js";
import { numberFormat } from "../functions.js";
import Facing from "../flats/facing.js";
import FlatinfoItem from "./flatinfo_item.js";
import "./plan.css";
import Switch from "./switch.js";
import LockIcon from "../Icons/Lock.js";
import { BrowserRouter as Router, Link } from "react-router-dom";

export default class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_block: "flat",
    };
  }

  onSwitch = (type) => {
    this.setState({
      current_block: type,
    });
  };

  render() {
    let flat_titles = [
      "1-комнатная квартира",
      "2-комнатная квартира",
      "3-комнатная квартира",
    ];
    let flat_title = this.props.flat.studio
      ? "Студия"
      : flat_titles[this.props.flat.rooms_amount - 1];

    return (
      <div className="Flat">
        <div className="FlatData">
          <Switch clicked={this.onSwitch} />
          {this.state.current_block === "flat" && (
            <div className="FlatImages">
              <div className="PlaningSlide">
                <div className="PlaningSlide-swipe">
                  <div
                    className="PlaningSlide-image PlaningSlide-image--planing no-bg"
                    style={{ width: "100%" }}
                  >
                    <a
                      className="PlaningSlide-background"
                      href={this.props.flat.preset}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        backgroundImage: "url(" + this.props.flat.preset + ")",
                      }}
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.current_block === "floor" && (
            <div className="FlatData-floor">
              <a
                className="PlaningSlide-background"
                href={this.props.flat.plan}
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundImage: "url(" + this.props.flat.plan + ")" }}
              ></a>
            </div>
          )}
          <div className="FlatInfo">
            <div className="FlatInfo-block">
              <Facing />
              <div className="FlatName FlatInfo-name">
                <div className="FlatName-title">
                  {this.props.flat.propertyType === "pantry"
                    ? "Кладовая"
                    : flat_title}
                </div>
              </div>
              <div className="FlatInfo-table">
                <div className="FlatInfo-row">
                  <FlatinfoItem label="Дом" text={this.props.flat.houseName} />
                  <FlatinfoItem label="Этаж" text={this.props.flat.floor} />
                </div>
                <div className="FlatInfo-row">
                  <FlatinfoItem
                    label="Стоимость"
                    text={
                      this.props.flat.price
                        ? numberFormat(this.props.flat.price.value)
                        : ""
                    }
                  />
                  <FlatinfoItem
                    label="Площадь"
                    text={
                      (this.props.flat.area
                        ? this.props.flat.area.area_total
                        : "") + " м2"
                    }
                  />
                </div>
                {this.props.flat.propertyType !== "pantry" && (
                <div className="FlatInfo-row">
                    <FlatinfoItem
                      label="Ипотека"
                      text={
                        this.props.flat.price
                          ? "от " +
                            numberFormat(
                              credit_calc(this.props.flat.price.value)
                            ) +
                            "/мес"
                          : ""
                      }
                    />

                </div>
                )}
              </div>
            </div>
            <div className="FlatsFooterBuy">
              <Link
                className="FlatsFooterBuy-buttonSubmit"
                to={"/booking/" + (this.props.flat.status === "BOOKED" ? 'booked' : this.props.flat.id)}
              >
                Купить
              </Link>
            </div>
          </div>
        </div>
        {this.props.flat.status === "BOOKED" && (
          <div
            className={
              this.props.flat.propertyType === "pantry"
                ? "LockIcon pantry"
                : "LockIcon"
            }
          >
            <LockIcon />
          </div>
        )}
        <span className="close" onClick={this.props.closed}>
          X Закрыть
        </span>
      </div>
    );
  }
}
