import React from "react";
import { DatetimePickerTrigger } from "rc-datetime-picker";
import InputMask from "react-input-mask";
import moment from "moment"
class RDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      startDate: props.value,
      weeks: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
    };
    this.onChangeValue = props.onChangeValue;
  }
  handleChange = (date) => {
    console.log(moment.isMoment(date))
    if(moment.isMoment(date))
  {}
    else if(moment(date.target.value, 'DD.MM.YYYY',true).isValid())
    date = moment(date.target.value, 'DD.MM.YYYY',true)
    else date = date.target.value
    
    
    this.setState({
      startDate: date,
    });
    this.onChangeValue(date); 
  };

  render() {
    console.log('err date - ',this.state.err  )
    return (
      <div className="r-date r-form-group">
        <div className="r-date__title ">{this.state.title}</div>
        <div className="r-input__box">
          <DatetimePickerTrigger
            moment={moment.isMoment(this.state.startDate)?this.state.startDate:moment()}
            onChange={this.handleChange}
            weeks={this.state.weeks}
            months={this.state.months}
            closeOnSelectDay={true}
          >
            <div className="r-input-box_date">
          <InputMask
          mask={"99.99.9999"}
          value={moment.isMoment(this.state.startDate)?this.state.startDate.format("DD.MM.YYYY"):this.state.startDate }
          onChange={this.handleChange}
          className={
            this.props.err ? "r-input-box invalid" : "r-input-box "
          }
          type="text"
          
        ></InputMask> 

            </div>
          </DatetimePickerTrigger>
        </div>
      </div>
    );
  }
}

export default RDate;
