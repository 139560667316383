export default function(state, action) {
  switch (action.type) {
    case "SET_FLATS":
      return {
        flats: action.flats,
      };
    default:
      return state;
  }
}
