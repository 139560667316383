import React, { Component } from "react";
import Routes from "./routes";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";

import "./style.css";
import "./App.css";
import "./media.css";
const initStore = { flats: [] };
const store = createStore(reducer, initStore);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}
