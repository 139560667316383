import React, {Component} from 'react';
//TODO: вынести чекбокс в функц компонент
export default class TypesCheck extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="FlatFilter-buttons">
                <div className="FlatFilter-buttonsWrapper FlatFilter-buttonsWrapperChecked">
                        <label className="FlatFilter-check FlatFilter-check--active">
                            <input type="checkbox" className="FlatFilter-checkChecked" name="types_select" onChange={this.props.changed}  value="residential" 
                            checked={this.props.types.includes("residential")} />
                            <div className="FlatFilter-checkCheckedCustom">
                                <span>Квартиры</span>
                            </div>
                        </label>
                        <label className="FlatFilter-check">
                            <input type="checkbox" className="FlatFilter-checkChecked" name="types_select" onChange={this.props.changed}  value="parking" 
                            checked={this.props.types.includes("parking")} />
                            <div className="FlatFilter-checkCheckedCustom">
                                <span>Машиноместо</span>
                            </div>
                        </label>
                        <label className="FlatFilter-check">
                            <input type="checkbox" className="FlatFilter-checkChecked" name="types_select" onChange={this.props.changed}  value="commercial" 
                            checked={this.props.types.includes("commercial")} />
                            <div className="FlatFilter-checkCheckedCustom">
                                <span>Офисы</span>
                            </div>
                        </label>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }
}