import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton';

import "../flats/fonts.css";
import "../flats/flats.css";
import Modal from "react-awesome-modal";
import Plan from "../plan";
import Flat from "./flat.js";
import { getFlatsList, getFloorPlans } from "../network";
import { sortByPriceAndType} from "../functions";

import { connect } from "react-redux";
import { setFlats } from "../../actions";

class Flats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      flats: [],
      flats_filtered: [],
      flat_imgs: [],
      modal: {
        visible: false,
        flat: {},
      },
    };
    this.dispatch = props.dispatch;
    this.flatList = React.createRef();
  }

  openModal = (flat) => {
    getFloorPlans(flat.house_id).then((plans) => {
      flat.plan = plans[flat.floor] !== undefined ? plans[flat.floor] : "";
    });
    flat.image = this.state.flat_imgs[flat.id];
    this.setState({
      modal: {
        visible: true,
        flat: flat,
      },
    });
  };

  closeModal = () => {
    this.setState({
      modal: {
        visible: false,
        flat: {},
      },
    });
  };

  componentWillMount() {
    this.setState({
      fetching: true,
    });
    getFlatsList().then((flats) => {
      flats.sort(sortByPriceAndType);

      this.setState({
        flats: flats,
        flats_filtered: flats,
        fetching: false,
      });
      this.dispatch(setFlats(flats));
      this.setResultCount();
      this.props.updateLimits(flats);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.houses.length !== this.props.houses.length ||
      prevProps.rooms.length !== this.props.rooms.length ||
      prevProps.types.length !== this.props.types.length ||
      prevProps.price_from !== this.props.price_from ||
      prevProps.price_to !== this.props.price_to ||
      prevProps.area_from !== this.props.area_from ||
      prevProps.area_to !== this.props.area_to ||
      prevProps.floor_from !== this.props.floor_from ||
      prevProps.floor_to !== this.props.floor_to
    ) {
      this.setResultCount();
    }
    if (this.props.applyClicked) {
      this.props.btnToggle();
      this.onFilterButtonClick();
    }
  }

  setResultCount() {
    let filtered = this.filterFlats();
    this.props.updated(filtered.length);
  }

  filterFlats() {

    return this.state.flats.filter((flat) => {
      let houses, rooms, types, price, area, floor;
      houses = rooms = types = price = area = floor = true;

        if (this.props.types.length && !this.props.types.includes(flat.typePurpose.toString())) types = false;
        if (this.props.houses.length && !this.props.houses.includes(flat.house_id.toString())) houses = false;
        if (
          this.props.rooms.length &&
          !(
            this.props.rooms.includes(flat.rooms_amount.toString()) &&
            !flat.studio
          ) &&
          !(this.props.rooms.includes("0") && flat.studio) &&
          !(this.props.rooms.includes("9") && flat.propertyType === "pantry")
        ) rooms = false;

        if (
          this.props.price_from !== 0 &&
          this.props.price_to !== 0 &&
          !(
            flat.price.value >= this.props.price_from &&
            flat.price.value <= this.props.price_to
          )
        ) price = false;

        const area_from = parseFloat(this.props.area_from);
        const area_to = parseFloat(this.props.area_to);
        if (!(
          flat.area.area_total >= area_from &&
          flat.area.area_total <= area_to
        )) area = false;

        if (
          this.props.floor_from != "" &&
          this.props.floor_to != "" &&
          !isNaN(this.props.floor_from) &&
          !isNaN(this.props.floor_to) &&
          !(
            flat.floor >= this.props.floor_from &&
            flat.floor <= this.props.floor_to
          )
        ) floor = false;

      return houses && rooms && types && price && area && floor;
    });
  }

  onFilterButtonClick() {
    this.flatList.current.classList.remove("FlatsResultTable--show");
    this.flatList.current.classList.add("FlatsResultTable--hide");
    this.startAnimation(() => {
      this.setState(
        {
          flats_filtered: this.filterFlats(),
        },
        () => {
          this.flatList.current.classList.remove("FlatsResultTable--hide");
          this.flatList.current.classList.add("FlatsResultTable--show");
        }
      );
    });
  }

  startAnimation(callback) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        callback();
      });
    });
  }

  render() {

    return (
      <React.Fragment>
        <div className="FlatsResultTable" ref={this.flatList}>
          <div className="FlatsResultTable-data">

            {this.state.flats_filtered.map((flat) => {
              return <Flat flat={flat} key={flat.id} modal={this.openModal} />;
            })}
            {this.state.fetching ? (
              <div>
                <Skeleton height={120} color="#fff" highlightColor="#aaa" style={{marginBottom: 20}} />
                <Skeleton height={120} color="#fff" highlightColor="#aaa" style={{marginBottom: 20}} />
                <Skeleton height={120} color="#fff" highlightColor="#aaa" style={{marginBottom: 20}} />
                <Skeleton height={120} color="#fff" highlightColor="#aaa" style={{marginBottom: 20}} />
                <Skeleton height={120} color="#fff" highlightColor="#aaa" style={{marginBottom: 20}} />
              </div>
            ) : (
              <div className="FlatsFooterText">
                <b>Не нашли подходящий вариант?</b>
                <p>
                  Возможно, мы ещё не успели обновить список квартир на сайте.
                  <br />
                  Позвоните по телефону <b>+7 (4212) 450-200</b> или{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=79147793968"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    напишите в WhatsApp
                  </a>{" "}
                  – мы поможем вам.
                </p>
              </div>
            )}
          </div>
        </div>
        <Modal
          className="modal-flat"
          visible={this.state.modal.visible}
          width="95%"
          height="82%"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <Plan closed={this.closeModal} flat={this.state.modal.flat} />
        </Modal>
      </React.Fragment>
    );
  }
}
Flats = connect()(Flats);

export default Flats;
