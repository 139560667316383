import React from "react";

let Items = (props) =>
  props.list.map((item) => (
    <li
      className="r-select__item"
      key={item.id}
      onClick={() => props.callback(item.id)}
    >
      {item.value}
    </li>
  ));

class RSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      value: props.value || null,
      placeholder: props.placeholder,
      isActive: false,
      list: props.items,
    };
    this.onChangeValue = props.onChangeValue;
    this.handleClick = this.handleClick.bind(this);
    this.callback = this.callback.bind(this);
  }
  getItemById(id) {
    return this.state.list.find((item) => item.id == id);
  }
  getHead() {
    return this.state.value
      ? this.getItemById(this.state.value).value
      : this.state.placeholder;
  }

  handleClick() {
    this.setState((state) => ({
      isActive: !state.isActive,
    }));
  }

  setValue(id) {
    this.setState((state) => ({
      value: id,
    }));
  }
  callback(id) {
    this.setValue(id);
    this.onChangeValue(id);
    this.handleClick();
  }

  render() {
    return (
      <div
        className={
          "r-select r-form-group" + (this.state.isActive ? " active" : "")
        }
      >
        <div className="r-select__title">{this.state.title}</div>
        <div className="r-select__box">
          <div
            className={
              this.props.err
                ? "r-input-box r-select__head invalid"
                : "r-input-box r-select__head"
            }
            onClick={this.handleClick}
          >
            <span>{this.getHead()}</span>
          </div>
          <ul className="r-select__body">
            <Items list={this.state.list} callback={this.callback} />
          </ul>
        </div>
      </div>
    );
  }
}

export default RSelect;
