import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import TheMain from "./components/TheMain";
import AppAdm from "./components/AppAdm";
import Booking from "./components/booking";
import Profile from "./components/profile";
import Finishing from "./components/finishing";
export default function Routes() {
  return (
    <Router>
      <Route exact path="/" component={TheMain} />
      <Route exact path="/catalog" component={TheMain} />
      <Route path="/adm" component={AppAdm} />
      <Route path="/booking/:id" component={Booking} />
      <Route path="/profile/:id" component={Profile} />
      <Route path="/finishing/:id" component={Finishing} />
    </Router>
  );
}
