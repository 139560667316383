import React from "react";
import InputMask from "react-input-mask";

const RInput = ({
  title,
  type,
  placeholder,
  value,
  onChangeValue,
  pattern,
  mask,
  err,
}) => {
  return (
    <div className="r-input  r-form-group">
      <div className="r-input__title">{title}</div>
      <div className="r-input__box">
        <InputMask
          mask={mask}
          value={value}
          onChange={onChangeValue}
          className={err ? "r-input-box invalid" : "r-input-box"}
          type={type || "text"}
          placeholder={placeholder}
          pattern={pattern}
        ></InputMask>
      </div>
    </div>
  );
};

export default RInput;
