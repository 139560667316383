import React, { Component } from "react";
import { numberFormat } from "../functions.js";
import { credit_calc } from "../calc.js";
import LockIcon from "../Icons/Lock.js";
import Facing from "./facing.js";
import { BrowserRouter as Router, Link } from "react-router-dom";

export default class Flat extends Component {
  render() {
    let flat_titles = ["Однокомнатная", "Двухкомнатная", "Трехкомнатная"];
    let flat_title = this.props.flat.studio
      ? "Студия"
      : flat_titles[this.props.flat.rooms_amount - 1];
    if (this.props.flat.propertyType === "pantry") flat_title = "Кладовая";
    if (this.props.flat.typePurpose === "commercial") flat_title = "Офис";
    if (this.props.flat.typePurpose === "parking") flat_title = "Машиноместо";
    return (
      <div className="FlatsResultTable-row">
        <div
          className="FlatsResultTable-link"
          onClick={() => this.props.modal(this.props.flat)}
        >
          <div className="FlatsResultTable-rowInfo">
            <div className="FlatsResultTable-rowLayout">
              <img src={this.props.flat.preset} alt="планировка" />
            </div>
            <div className="FlatsResultTable-rowTitle">
              <div className="FlatsResultTable-rowTitle--section">
                {this.props.flat.houseName}
              </div>
              <div className="FlatsResultTable-rowTitle--room">
                {flat_title} {this.props.flat.area.area_total} м²
              </div>
              <div className="FlatsResultTable-rowTitle--feature">
                <span>{this.props.flat.floor} этаж</span>
              </div>
              <div className="FlatsResultTable-rowBanners">
                <Facing />
                {this.props.flat.status === "BOOKED" && (
                  <div
                    className={(this.props.flat.propertyType === "pantry")?"LockIcon pantry" :"LockIcon"}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <LockIcon />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="FlatsResultTable-rowPrice">
            <div className="FlatsResultTable-rowPriceMeter">
              <div className="FlatsResultTable-rowPriceTitle">
                {numberFormat(this.props.flat.price.value)}
              </div>
              {this.props.flat.propertyType !== "pantry" && (
<div>от {numberFormat(credit_calc(this.props.flat.price.value))}/мес
</div>)}
            </div>
            <Link
              className="FlatsResultTable-buttonSubmit"
              to={"/booking/" + (this.props.flat.status === "BOOKED" ? 'booked' : this.props.flat.id)}
            >
              Купить
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
